import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { map } from 'rxjs/operators';
import { LoaderService } from '../sharedmodules/loader.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private httpClient: HttpClient, private loaderService: LoaderService) { }

  login(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/signin');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
    
      return res;
    }))
    
  }

  loginGuestUser(data, visitId) {
    let reqUrl = CommonService.getApiUrl('/visitor/signin/' + visitId);
    return this.httpClient.put(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  register(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/register');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  registerGuestUser(data, visitId) {
    let reqUrl = CommonService.getApiUrl('/visitor/register/' + visitId);
    return this.httpClient.put(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  mobileverify(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/verifySMS');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  registerprofile(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/profile');
    return this.httpClient.put(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  getprofile() {
    let reqUrl = CommonService.getApiUrl('/visitor/profile');
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  resendOTP() {
    let reqUrl = CommonService.getApiUrl('/visitor/resend/verificationCode');
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  forgetPassword(data) {
    let reqUrl = CommonService.getApiUrl('​/visitor/forgetpassword');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  createPassword(data) {
    let reqUrl = CommonService.getApiUrl('​/visitor/Createpassword');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  resetPassword(data) {
    let reqUrl = CommonService.getApiUrl('​/visitor/resetpassword');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  sendVerifyCode(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/verficationcode/send');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      // this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  confirmVerifyCode(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/verficationcode/confirm');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  getCountryCode() {
    let reqUrl = CommonService.getApiUrl('/visitor/country/code');
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }
}
